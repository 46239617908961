import { MenuEntry } from '@encodix/egoninfo'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: 'https://egonswap.io',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://dex.egonswap.io/#/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://dex.egonswap.io/#/pool',
      },

    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: 'https://egonswap.io/#/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: 'https://egonswap.io/#/pools',
  },
  {
    label: t('Launchpad'),
    icon: 'PoolIcon',
    href: 'https://egonswap.io/#/comingsoon',
  },
  //  {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: 'https://egonswap.io/#/comingsoon',
  // },
  // {
  //   label: t('Referral'),
  //   icon: 'GroupsIcon',
  //   href: 'https://egonswap.io/#/referrals',
  // },
  {
    label: t('Info Analytics'),
    icon: 'InfoIcon',
    href: '/',
  },
  {
    label: t('Contact Us'),
    icon: 'GroupsIcon',
    href: 'https://egonswap.io/#/contact',
  },
  {
    label: t('Audit Report'),
    icon: 'TicketIcon',
    items: [
      {
        label: 'TechRate',
        href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/December/EgonSwap.pdf',
      },
      {
        label: 'Cyber Security',
        href: 'https://github.com/JorgeRodriguezsec/CTDsec/blob/main/Audits/Cybersecurity_Audit_CTDSEC_EGONSWAP.pdf',
      },
      {
        label: 'Certik',
        href: 'https://egonswap.io/comingsoon',
      },
    ]
  },
  {
    label: t('Listings'),
    icon: 'NftIcon',
    items: [
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x8669CD3841029CdAa5AeD2880bbc24fb1FE6d296',
      },
    ]
  },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: 'https://egonswap.io/prediction',
  // },

  // {
  //   label: t('Collectibles'),
  //   icon: 'NftIcon',
  //   href: 'https://egonswap.io/collectibles',
  // },
  // {
  //   label: t('Team Battle'),
  //   icon: 'TeamBattleIcon',
  //   href: 'https://egonswap.io/competition',
  // },
  // {
  //   label: t('Teams & Profile'),
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: t('Leaderboard'),
  //       href: 'https://egonswap.io/teams',
  //     },
  //     {
  //       label: t('Task Center'),
  //       href: 'https://egonswap.io/profile/tasks',
  //     },
  //     {
  //       label: t('Your Profile'),
  //       href: 'https://egonswap.io/profile',
  //     },
  //   ],
  // },
  // {
  //   label: t('IFO'),
  //   icon: 'IfoIcon',
  //   href: 'https://egonswap.io.finance/ifo',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      // {
      //   label: t('Contact'),
      //   href: 'https://docs.egonswap.io/contact-us',
      // },
      // {
      //   label: t('Voting'),
      //   href: 'https://voting.egonswap.io',
      // },
      {
        label: t('Github'),
        href: 'https://github.com/egonswap',
      },
      {
        label: t('Docs'),
        href: 'https://docs.egonswap.io',
      },
      {
        label: t('Telegram'),
        href: 'https://t.me/EagleNetWorkApp',
      },
      // {
      //   label: t('Blog'),
      //   href: 'https://egonswap.medium.com',
      // },
      // {
      //   label: t('Merch'),
      //   href: 'https://egonswap.creator-spring.com/',
      // },
    ],
  },
]

export default config
